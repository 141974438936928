<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td style="text-align: center;">
      <v-checkbox v-model="itemStatus" :disabled="props.item.status == 3" :color="props.item.type.color"  hide-details></v-checkbox>
    </td>
    <td class="text-xs-left" style="padding: 0px !important; min-width: 70px;">
      <strong>{{ props.item.description }}</strong>
    </td>
    <td class="text-xs-left" style="padding: 0px !important; min-width: 70px;">
      <v-list v-if="props.item.date_formated" dense two-line class="tasks__date" style="padding-top: 0px; padding-bottom: 0px;">
        <v-list-tile class="tasks__list-tile">
          <v-list-tile-avatar>
            <v-icon color="primary" medium>date_range</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              <span>Em </span>
              <strong>{{ props.item.date_formated }}</strong>
              <strong v-if="props.item.time_formated"> às {{ props.item.time_formated }}</strong>
            </v-list-tile-sub-title>
            <v-list-tile-sub-title v-if="props.item.date_end_formated">
              <span> até </span>
              <strong v-if="props.item.date_formated != props.item.date_end_formated">{{ props.item.date_end_formated }}</strong>
              <strong v-if="props.item.time_end_formated"> às {{ props.item.time_end_formated }}</strong>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list v-else dense two-line class="tasks__date" style="padding-top: 0px; padding-bottom: 0px;">
        <v-list-tile class="tasks__list-tile">
          <v-list-tile-avatar>
            <v-icon color="primary" large>event_busy</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title v-html="props.item.date_end_formated">
              Não definido
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <span v-if="props.item.person && props.item.person.name" v-text="props.item.person.name"></span>
      <span v-else>Não definido</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <span v-if="props.item.person && props.item.person.name" v-text="props.item.person.name"></span>
      <span v-else>Não definido</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <div v-if="props.item.type.code != 100 && props.item.type.code != 200">
        <v-btn v-if="props.item.business_id" :style="{color: props.item.type.color}" outline small block @click="goToBusiness(props.item)">
          <v-icon v-text="props.item.type.icon"></v-icon>
          <span>Ver <span v-text="props.item.type.title"></span></span>
        </v-btn>
        <v-btn v-else :style="{color: props.item.type.color}" outline small block @click="createNewBusiness(props.item)">
          <v-icon v-text="props.item.type.icon"></v-icon>
          <span>Criar <span v-text="props.item.type.title"></span></span>
        </v-btn>
      </div>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Tasks',
    data: () => {
      return {}
    },
    methods: {
      goToBusiness: function (task) {
        console.log(task)
        this.$router.push({
          name: task.type.name,
          params: {id: task.business_id}
        })
      },
      createNewBusiness: function (task) {
        var config = {
          wiConfig: task.type.name + '-open',
          data: {
            task_id: task.id,
            person_id: task.person_id,
            person: task.person,
            date_end: task.date,
            collaborator_id: task.responsible_id,
            collaborator: task.responsible
          }
        }
        this.$WiEditDialog(config)
      }
    },
    computed: {
      itemStatus: {
        get () {
          var status = this.props.item && this.props.item.status === 1
          return status
        },
        set (value) {
          this.$WiApiPut({
            uri: 'tasks/task',
            id: this.props.item.id,
            data: { status: value },
            callback: () => {}
          })
          this.config.refresh()
        }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .tasks__date {
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    margin: 0;
    text-align: center;
  }
  .tasks__list-tile >>> .v-list__tile {
    height: 40px !important;
    padding: 0 5px;
  }
</style>